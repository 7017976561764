

@media screen and (max-width: 767px){
  .category-dl__count {
    padding: 0;
    &.is_combined_product {
      height: 56px;
    }
  }
  .category-dl__count--total {
    width: 28% !important;
  }
  .category-dl__count--set-total {
    width: 28% !important;
    &.is_combined_product {
      width: 50% !important;
      border-left: none;
    }
  }
  .category-dl__count--price {
    width: 44% !important;
    &.is_combined_product {
      width: 50% !important;
    }
  }
}

